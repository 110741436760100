import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {

  result:any;
  // params:any;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.route.data['value'].din) {
      this.result = this.route.data['value'].din;
      // this.params = JSON.stringify(this.route.data['value'].params);
      this.scroll();
    } else {
      this.back();
    }
  }

  back() {
    this.router.navigate(['']);
    this.scroll();
  }

  scroll() {
    document.querySelector('#target').scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"});
  }

}
