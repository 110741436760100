// console.log("DIN Calculator 2.0");

var code = {
  1 : "A",
  2 : "B",
  3 : "C",
  4 : "D",
  5 : "E",
  6 : "F",
  7 : "G",
  8 : "H",
  9 : "I",
  10 : "J",
  11 : "K",
  12 : "L",
  13 : "M",
  14 : "N",
  15 : "O"
}

var weight = {
  "10-13" : 1,
  "14-17" : 2,
  "18-21" : 3,
  "22-25" : 4,
  "26-30" : 5,
  "31-35" : 6,
  "36-41" : 7,
  "42-48" : 8,
  "49-57" : 9,
  "58-66" : 10,
  "67-78" : 11,
  "79-94" : 12,
  ">=95"  : 13
}

var height = {
  "<=148"   : 8,
  "149-157" : 9,
  "158-166" : 10,
  "167-178" : 11,
  "179-194" : 12,
  ">=195"   : 13
}

var code_size = {
  "A" : {"<=250":0.75, "251-270":0.75, "271-290":0   , "291-310":0   , "311-330":0   , ">330":0},
  "B" : {"<=250":1   , "251-270":1   , "271-290":0.75, "291-310":0   , "311-330":0   , ">330":0},
  "C" : {"<=250":1.5 , "251-270":1.25, "271-290":1   , "291-310":0   , "311-330":0   , ">330":0},
  "D" : {"<=250":1.75, "251-270":1.5 , "271-290":1.5 , "291-310":1.25, "311-330":0   , ">330":0},
  "E" : {"<=250":2.25, "251-270":2   , "271-290":1.75, "291-310":1.5 , "311-330":1.25, ">330":0},
  "F" : {"<=250":2.75, "251-270":2.5 , "271-290":2.25, "291-310":2   , "311-330":1.75, ">330":1.75},
  "G" : {"<=250":3.5 , "251-270":3   , "271-290":2.75, "291-310":2.5 , "311-330":2.25, ">330":2},
  "H" : {"<=250":0   , "251-270":3.5 , "271-290":3   , "291-310":3   , "311-330":2.75, ">330":2.5},
  "I" : {"<=250":0   , "251-270":4.5 , "271-290":4   , "291-310":3.5 , "311-330":3.25, ">330":3},
  "J" : {"<=250":0   , "251-270":5.5 , "271-290":5   , "291-310":4.5 , "311-330":4   , ">330":3.5},
  "K" : {"<=250":0   , "251-270":6.5 , "271-290":6   , "291-310":5.5 , "311-330":5   , ">330":4.5},
  "L" : {"<=250":0   , "251-270":7.5 , "271-290":7   , "291-310":6.5 , "311-330":6   , ">330":5.5},
  "M" : {"<=250":0   , "251-270":0   , "271-290":8.5 , "291-310":8   , "311-330":7   , ">330":6.5},
  "N" : {"<=250":0   , "251-270":0   , "271-290":10  , "291-310":9.5 , "311-330":8.5 , ">330":8},
  "O" : {"<=250":0   , "251-270":0   , "271-290":11.5, "291-310":11  , "311-330":10  , ">330":9.5}
}

var age = {
  "<16"   : 0,
  "16-50" : 0,
  ">50"   : -1
}

var level = {
  "B" : 0,
  "I" : 1,
  "A" : 2
}

var din_calculator = function(p_age, p_level, p_weight, p_height, p_size) {
  var rcode = 0;
  if (weight[p_weight] === height[p_height]) {
    rcode = weight[p_weight];
  } else if (weight[p_weight] > height[p_height]) {
    rcode = height[p_height];
  } else {
    rcode = weight[p_weight];
  }
  rcode = rcode + age[p_age] + level[p_level];
  return code_size[code[rcode]][p_size];
}

//var result = din_calculator("16-50", "I", "79-94", "179-194", "271-290")
//console.log("Result : " + result);

module.exports.calculate = din_calculator;
