import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {FormComponent} from "./form/form.component";
import {ResultComponent} from "./result/result.component";

const routes: Routes = [
  { path: '', component: FormComponent },
  { path: 'result/:din', component: ResultComponent },
  { path: 'result', component: ResultComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      anchorScrolling: 'enabled'
    }
  ), CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
